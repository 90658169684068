import BSButton from 'react-bootstrap/Button';
import BSSpinner from 'react-bootstrap/Spinner';

import { AcaoPermissaoPapelUsuarioEnum } from '@tamborineapps/lib-enums';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { DetailCard } from '../../components/details/datail-card';
import DetailTitle from '../../components/details/detail-title';
import { Form } from '../../components/form/form';
import { FormControl } from '../../components/form/form-control';
import { FormSelect } from '../../components/form/form-select';
import RbacPage from '../../components/role-based-access-control/role-based-access-control-page';
import { ClassTypesEnum, contextosRotinasMap } from '../../helpers';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch } from '../../store/hooks-redux';
import { executarRotinaPeriodica } from './rotina-periodica.redux';

type ExecutarRotinaFormFields = {
  dataExecucao: string;
  rotina: string;
};

const RotinaPeriodicaPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { showToast } = useToasts();
  const navigate = useNavigate();

  const [rotinaEmProcessamento, setRotinaEmProcessamento] = useState<boolean>(false);

  const form = useForm<ExecutarRotinaFormFields>();
  const { control, reset } = form;

  const onSubmitHandler = useCallback(
    (data: ExecutarRotinaFormFields) => {
      setRotinaEmProcessamento(true);
      const { dataExecucao, rotina } = data;
      const { app } = contextosRotinasMap[rotina];

      dispatch(executarRotinaPeriodica({ data: { dataExecucao, rotina, app } }))
        .then(() => {
          showToast({ message: 'Processamento da rotina finalizado', type: ClassTypesEnum.SUCCESS });

          return navigate('/protocolos-processamento-lote/rotinas');
        })
        .catch((error: Error) => {
          setRotinaEmProcessamento(false);
          showToast({ message: error.message, type: ClassTypesEnum.DANGER });
        });
    },
    [dispatch, navigate, showToast]
  );

  const onCancelHandler = () => {
    reset();
  };

  return (
    <RbacPage acoesPermissao={AcaoPermissaoPapelUsuarioEnum.EXECUCAO_ROTINA}>
      <DetailCard>
        <div className="mb-5">
          <DetailTitle renderBackButton={false}>Executar rotina</DetailTitle>
        </div>

        <Form form={form} onSubmit={onSubmitHandler}>
          <div className="row">
            <div className="col-lg-4 col-md-6 mb-4">
              <FormSelect
                control={control}
                name="rotina"
                placeholder="Selecione a rotina periódica"
                label="Rotina"
                options={Object.entries(contextosRotinasMap)?.map(([key, value]) => ({
                  key: key,
                  value: key,
                  label: value.nome,
                }))}
                rules={{ required: true }}
              />
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
              <FormControl
                control={control}
                name="dataExecucao"
                label="Data de execução"
                type="date"
                rules={{ required: true }}
              />
            </div>

            <div className="col-12 d-flex">
              <BSButton className="mx-1" variant="light" onClick={onCancelHandler} disabled={rotinaEmProcessamento}>
                Cancelar
              </BSButton>
              <BSButton className="mx-1 px-4" variant="primary" type="submit" disabled={rotinaEmProcessamento}>
                Executar rotina
              </BSButton>
            </div>
          </div>
        </Form>

        {rotinaEmProcessamento && (
          <div className="d-flex justify-content-center align-items-center mt-5">
            <div className="me-3">
              <BSSpinner variant="primary" />
            </div>
            <strong>Rotina em processamento...</strong>
          </div>
        )}
      </DetailCard>
    </RbacPage>
  );
};

export default RotinaPeriodicaPage;
