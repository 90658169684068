import { AcaoPermissaoPapelUsuarioEnum, StatusProcessamentoEnum } from '@tamborineapps/lib-enums';
import { useCallback, useEffect, useMemo } from 'react';
import BSAccordion from 'react-bootstrap/Accordion';
import BSBadge from 'react-bootstrap/Badge';
import { Link, useParams } from 'react-router-dom';
import { DetailCard } from '../../components/details/datail-card';
import DetailElement from '../../components/details/detail-element';
import DetailSubTitle from '../../components/details/detail-subtitle';
import DetailTitle from '../../components/details/detail-title';
import { Loading } from '../../components/loading';
import RbacPage from '../../components/role-based-access-control/role-based-access-control-page';
import {
  ApiSingleElementResponse,
  FormatValueEnum,
  rotinasContaCartaoLabelMap,
  statusProcessamentoColorMap,
  tipoProcessamentoJornadaContaCartaoLabelMap,
} from '../../helpers';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import { loadContaCartao, selectContaCartaoById } from '../contas-cartao/contas-cartao.redux';
import {
  loadProtocoloProcessamentoConta,
  selectLoadingStateByFiltersProtocoloProcessamentoConta,
  selectProtocoloProcessamentoContaById,
} from './protocolo-processamento-conta.redux';

const DetalhesProtocoloProcessamentoContaPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const params = useParams();
  const protocoloProcessamentoContaId = params.protocoloProcessamentoContaId as string;

  const filters = useMemo(() => ({ protocoloProcessamentoContaId }), [protocoloProcessamentoContaId]);
  const protocoloProcessamentoConta = useSelector((state) =>
    selectProtocoloProcessamentoContaById(state, protocoloProcessamentoContaId)
  );
  const loadingState = useSelector((state) => selectLoadingStateByFiltersProtocoloProcessamentoConta(state, filters));
  const contaCartao = useSelector((state) => selectContaCartaoById(state, protocoloProcessamentoConta?.contaCartaoId));

  const statusProcessadoComSucesso = protocoloProcessamentoConta?.sucesso
    ? StatusProcessamentoEnum.SUCESSO
    : StatusProcessamentoEnum.ERRO;

  const _loadContaCartao = useCallback(
    (protocoloProcessamentoConta: any) =>
      dispatch(loadContaCartao({ contaCartaoId: protocoloProcessamentoConta?.contaCartaoId })).catch(
        (error: Error) => error
      ),
    [dispatch]
  );

  const _loadProtocoloProcessamentoConta = useCallback(
    () =>
      dispatch(loadProtocoloProcessamentoConta({ protocoloProcessamentoContaId }))
        .then(({ payload: { data } }: ApiSingleElementResponse) => _loadContaCartao(data))
        .catch((error: Error) => error),
    [_loadContaCartao, dispatch, protocoloProcessamentoContaId]
  );

  useEffect(() => {
    _loadProtocoloProcessamentoConta();
  }, [_loadProtocoloProcessamentoConta]);

  if (!protocoloProcessamentoConta) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Loading notFoundMessage="Processamento da conta cartão não encontrado" loadingState={loadingState} />
      </div>
    );
  }

  return (
    <RbacPage acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_PROTOCOLO_PROCESSAMENTO_CONTA}>
      <div className="mb-5">
        <DetailCard>
          <div className="row mb-5">
            <div className="col-md-8 col-sm-12 mb-3">
              <DetailTitle>
                <div className="d-flex">
                  <span>Protocolo de processamento de conta-cartão</span>
                  <span className="mx-3">-</span>
                  <span>
                    <Link to={`/contas-cartao/${protocoloProcessamentoConta?.contaCartaoId}`}>
                      Conta {contaCartao?.numero}
                    </Link>
                  </span>
                </div>
              </DetailTitle>
            </div>

            <div className="col-md-4 col-sm-12 text-md-end text-sm-start">
              <h4 className="mb-0">
                <BSBadge
                  pill
                  bg={
                    statusProcessamentoColorMap[statusProcessadoComSucesso as keyof typeof statusProcessamentoColorMap]
                  }
                >
                  {statusProcessadoComSucesso}
                </BSBadge>
              </h4>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-6 mb-4">
              <DetailElement
                descricao="Data referência"
                valor={protocoloProcessamentoConta.data}
                format={FormatValueEnum.DATA}
              />
              <DetailElement
                descricao="Tipo de processamento"
                valor={protocoloProcessamentoConta.tipoProcessamento}
                format={FormatValueEnum.ENUM}
                map={tipoProcessamentoJornadaContaCartaoLabelMap}
              />
            </div>
          </div>
        </DetailCard>
      </div>

      {Boolean(protocoloProcessamentoConta?.tentativas?.length !== 0) && (
        <DetailCard>
          <div className="row">
            <div className="mb-3">
              <DetailSubTitle>Tentativas de processamento</DetailSubTitle>
            </div>
          </div>

          <div className="mb-3">
            <DetailElement descricao="Total de tentativas" valor={protocoloProcessamentoConta.numeroTentativas} />
          </div>

          <div className="col-12">
            <BSAccordion>
              {protocoloProcessamentoConta?.tentativas?.map((tentativa: any, index: string) => (
                <BSAccordion.Item eventKey={index} key={index}>
                  <BSAccordion.Header>
                    Tentativa #{index + 1} - {protocoloProcessamentoConta?.sucesso ? 'Sucesso' : 'Falha'}
                  </BSAccordion.Header>
                  <BSAccordion.Body>
                    <DetailElement
                      descricao="Data do início do processamento"
                      valor={tentativa?.dataHoraProcessamento}
                      format={FormatValueEnum.DATE_TIME}
                    />

                    {tentativa?.erro && (
                      <>
                        <DetailElement
                          descricao="Rotina"
                          valor={
                            rotinasContaCartaoLabelMap[
                              tentativa.erro?.rotina as keyof typeof rotinasContaCartaoLabelMap
                            ]
                          }
                        />
                        <DetailElement descricao="Mensagem" valor={tentativa.erro?.mensagem} />
                        <DetailElement descricao="Stack do erro" valor={tentativa.erro?.stack} />
                      </>
                    )}
                  </BSAccordion.Body>
                </BSAccordion.Item>
              ))}
            </BSAccordion>
          </div>
        </DetailCard>
      )}
    </RbacPage>
  );
};

export default DetalhesProtocoloProcessamentoContaPage;
