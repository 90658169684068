import BSButton from 'react-bootstrap/Button';

import { AcaoPermissaoPapelUsuarioEnum } from '@tamborineapps/lib-enums';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { DetailCard } from '../../components/details/datail-card';
import DetailTitle from '../../components/details/detail-title';
import { Form } from '../../components/form/form';
import { FormControl } from '../../components/form/form-control';
import { FormSelect } from '../../components/form/form-select';
import RbacPage from '../../components/role-based-access-control/role-based-access-control-page';
import { ClassTypesEnum, tipoProcessamentoJornadaContaCartaoLabelMap } from '../../helpers';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch } from '../../store/hooks-redux';
import { executarJornadaContasCartao } from './executar-jornada-contas-cartao.redux';

type ExecutarJornadaContasCartaoFormFields = {
  tipoProcessamento: string;
  dataExecucao: string;
};

const ExecutarJornadaContasCartaoPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { showToast } = useToasts();

  const form = useForm<ExecutarJornadaContasCartaoFormFields>();
  const { control, reset } = form;

  const onSubmitHandler = useCallback(
    (data: ExecutarJornadaContasCartaoFormFields) => {
      dispatch(executarJornadaContasCartao({ data }))
        .then(() => {
          showToast({
            message: 'Processamento da jornada de contas-cartão inicializado com sucesso',
            type: ClassTypesEnum.SUCCESS,
          });
          reset();
        })
        .catch((error: Error) => {
          showToast({ message: error.message, type: ClassTypesEnum.DANGER });
        });
    },
    [dispatch, reset, showToast]
  );

  const onCancelHandler = () => {
    reset();
  };

  return (
    <RbacPage acoesPermissao={AcaoPermissaoPapelUsuarioEnum.EXECUCAO_JORNADA_CONTAS_CARTAO}>
      <DetailCard>
        <div className="mb-5">
          <DetailTitle renderBackButton={false}>Executar jornada de contas-cartão</DetailTitle>
        </div>

        <Form form={form} onSubmit={onSubmitHandler}>
          <div className="row">
            <div className="col-lg-4 col-md-6 mb-4">
              <FormSelect
                control={control}
                name="tipoProcessamento"
                placeholder="Selecione o tipo de processamento"
                label="Tipo de processamento"
                options={Object.entries(tipoProcessamentoJornadaContaCartaoLabelMap)?.map(([key, value]) => ({
                  key: key,
                  value: key,
                  label: value,
                }))}
                rules={{ required: true }}
              />
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
              <FormControl
                control={control}
                name="dataExecucao"
                label="Data de execução"
                type="date"
                rules={{ required: true }}
              />
            </div>

            <div className="col-12 d-flex">
              <BSButton className="mx-1" variant="light" onClick={onCancelHandler}>
                Cancelar
              </BSButton>
              <BSButton className="mx-1 px-4" variant="primary" type="submit">
                Executar
              </BSButton>
            </div>
          </div>
        </Form>
      </DetailCard>
    </RbacPage>
  );
};

export default ExecutarJornadaContasCartaoPage;
