import React, { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { DetailCard } from '../../components/details/datail-card';
import DetailElement from '../../components/details/detail-element';
import DetailTitle from '../../components/details/detail-title';
import { Loading } from '../../components/loading';
import Page from '../../components/page';
import { ApiSingleElementResponse, FormatValueEnum, naturezaTransacaoLabelMap } from '../../helpers';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import { loadContaCartao, selectContaCartaoById } from '../contas-cartao/contas-cartao.redux';
import { loadProduto, selectProdutoById } from '../produtos/produtos.redux';
import { loadRoteiroContabil, selectRoteiroContabilById } from '../roteiros-contabeis/roteiro-contabil.redux';
import { loadTransacao, selectTransacaoById } from '../transacoes/transacoes.redux';
import {
  loadLancamentoContabil,
  selectLancamentoContabilById,
  selectLancamentoContabilLoadingStateByFilters,
} from './lancamento-contabil.redux';

const DetalhesLancamentoContabilPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const params = useParams();
  const lancamentoContabilId = params.lancamentoContabilId as string;

  const filters = useMemo(() => ({ lancamentoContabilId }), [lancamentoContabilId]);
  const lancamentoContabil = useSelector((state) => selectLancamentoContabilById(state, lancamentoContabilId));
  const loadingState = useSelector((state) => selectLancamentoContabilLoadingStateByFilters(state, filters));

  const contaCartao = useSelector((state) => selectContaCartaoById(state, lancamentoContabil?.contaCartao));
  const produto = useSelector((state) => selectProdutoById(state, lancamentoContabil?.produto));
  const roteiroContabil = useSelector((state) => selectRoteiroContabilById(state, lancamentoContabil?.roteiroContabil));
  const transacao = useSelector((state) => selectTransacaoById(state, lancamentoContabil?.transacao));

  const loadEntidadesRelacionadas = useCallback(
    (lancamentoContabil: any) => {
      dispatch(loadContaCartao({ contaCartaoId: lancamentoContabil?.contaCartao })).catch((error: Error) => error);
      dispatch(loadProduto({ produtoId: lancamentoContabil?.produto })).catch((error: Error) => error);
      dispatch(loadRoteiroContabil({ roteiroContabilId: lancamentoContabil?.roteiroContabil })).catch(
        (error: Error) => error
      );
      if (lancamentoContabil?.transacao) {
        dispatch(loadTransacao({ transacaoId: lancamentoContabil?.transacao })).catch((error: Error) => error);
      }
    },
    [dispatch]
  );

  const _loadLancamentoContabil = useCallback(
    () =>
      dispatch(loadLancamentoContabil({ lancamentoContabilId }))
        .then(({ payload: { data } }: ApiSingleElementResponse) => loadEntidadesRelacionadas(data))
        .catch((error: Error) => error),
    [lancamentoContabilId, dispatch, loadEntidadesRelacionadas]
  );

  useEffect(() => {
    _loadLancamentoContabil();
  }, [_loadLancamentoContabil]);

  if (!lancamentoContabil) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Loading notFoundMessage="Lançamento contábil não encontrado!" loadingState={loadingState} />
      </div>
    );
  }

  return (
    <Page>
      <div className="mb-5">
        <DetailCard>
          <div className="mb-5">
            <DetailTitle>Lançamento contábil: {lancamentoContabil.descricao}</DetailTitle>
          </div>

          <div className="row mb-4">
            <div className="col-lg-4 col-md-6 mb-4">
              <DetailElement
                descricao="Conta cartão"
                valor={contaCartao?.numero}
                link={`/contas-cartao/${lancamentoContabil.contaCartao}`}
              />
              <DetailElement
                descricao="Data do lançamento"
                valor={lancamentoContabil?.dataLancamento}
                format={FormatValueEnum.DATA}
              />
              {lancamentoContabil?.transacao && (
                <DetailElement
                  descricao="Transação"
                  valor={transacao?.descricao}
                  link={`transacoes/${lancamentoContabil.transacao}`}
                />
              )}
              <DetailElement
                descricao="Produto"
                valor={produto?.nome}
                link={`/produtos/${lancamentoContabil.produto}`}
              />
              <DetailElement
                descricao="Natureza"
                valor={lancamentoContabil.natureza}
                format={FormatValueEnum.ENUM}
                map={naturezaTransacaoLabelMap}
              />
              <DetailElement
                descricao="Roteiro contábil"
                valor={roteiroContabil?.descricao}
                link={`/roteiros-contabeis/${roteiroContabil?._id}`}
              />
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
              <DetailElement descricao="Conta contábil" valor={lancamentoContabil.contaContabil} />
              <DetailElement descricao="Conta contra partida" valor={lancamentoContabil.contaContraPartida} />
              <DetailElement descricao="Valor" valor={lancamentoContabil.valor} format={FormatValueEnum.BRL} />
            </div>
          </div>
        </DetailCard>
      </div>
    </Page>
  );
};

export default DetalhesLancamentoContabilPage;
