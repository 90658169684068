import { AcaoPermissaoPapelUsuarioEnum } from '@tamborineapps/lib-enums';
import React from 'react';
import BSForm from 'react-bootstrap/Form';
import BSListGroup from 'react-bootstrap/ListGroup';
import BSTab from 'react-bootstrap/Tab';
import BSTabs from 'react-bootstrap/Tabs';
import { Control } from 'react-hook-form';
import { FormCheck } from '../../components/form/form-check';

type TabsPermissoesPapelUsuarioProps = {
  eventKey: string;
  label: string;
  entidades: PermissaoEntidadePapelUsuarioProps[];
};

type PermissaoEntidadePapelUsuarioProps = {
  label: string;
  permissoesMap: {
    [key in AcaoPermissaoPapelUsuarioEnum]?: string;
  };
};

const permissoesEntidades: TabsPermissoesPapelUsuarioProps[] = [
  {
    eventKey: 'contasCartao',
    label: 'Contas',
    entidades: [
      {
        label: 'Contas-cartão',
        permissoesMap: {
          [AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_CONTA_CARTAO]: 'Visualização',
          [AcaoPermissaoPapelUsuarioEnum.ALTERACAO_DIA_VENCIMENTO_CONTA_CARTAO]: 'Alterar dia de vencimento',
          [AcaoPermissaoPapelUsuarioEnum.ALTERACAO_LIMITE_CREDITO_CONTA_CARTAO]: 'Alterar limite de crédito',
          [AcaoPermissaoPapelUsuarioEnum.CANCELAMENTO_TRANSACAO]: 'Cancelar transações',
          [AcaoPermissaoPapelUsuarioEnum.CONFIRMACAO_TRANSACAO]: 'Confirmar transação',
          [AcaoPermissaoPapelUsuarioEnum.INCLUSAO_BLOQUEIO_PERMANENTE_CONTA_CARTAO]: 'Incluir bloqueio permanente',
          [AcaoPermissaoPapelUsuarioEnum.INCLUSAO_BLOQUEIO_CONTA_CARTAO]: 'Incluir bloqueio',
          [AcaoPermissaoPapelUsuarioEnum.REMOCAO_BLOQUEIO_CONTA_CARTAO]: 'Remover bloqueio',
          [AcaoPermissaoPapelUsuarioEnum.SOLICITACAO_CARTAO_ADICIONAL_CONTA_CARTAO]: 'Solicitar cartão adicional',
        },
      },
      {
        label: 'Cartões',
        permissoesMap: {
          [AcaoPermissaoPapelUsuarioEnum.ALTERACAO_PERMISSOES_CARTAO]: 'Alterar permissões',
          [AcaoPermissaoPapelUsuarioEnum.SOLICITACAO_SEGUNDA_VIA_CARTAO]: 'Solicitar nova via',
          [AcaoPermissaoPapelUsuarioEnum.SOLICITACAO_CARTA_SENHA_CARTAO]: 'Solicitar carta senha',
          [AcaoPermissaoPapelUsuarioEnum.INCLUSAO_BLOQUEIO_CARTAO]: 'Incluir bloqueio',
          [AcaoPermissaoPapelUsuarioEnum.INCLUSAO_BLOQUEIO_PERMANENTE_CARTAO]: 'Incluir bloqueio permanente',
          [AcaoPermissaoPapelUsuarioEnum.REMOCAO_BLOQUEIO_CARTAO]: 'Remover bloqueio',
        },
      },
      {
        label: 'Histórico da conta cartão',
        permissoesMap: {
          [AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_HISTORICO_CONTA_CARTAO]: 'Visualização',
        },
      },
      {
        label: 'Histórico do cartão',
        permissoesMap: {
          [AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_HISTORICO_CARTAO]: 'Visualização',
        },
      },
      {
        label: 'Histórico de cliente',
        permissoesMap: {
          [AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_HISTORICO_CLIENTE]: 'Visualização',
        },
      },
      {
        label: 'Usuário cliente',
        permissoesMap: {
          [AcaoPermissaoPapelUsuarioEnum.REMOCAO_BLOQUEIO_POR_SENHA_USUARIO_CLIENTE]:
            'Remover bloqueio senha usuario-cliente',
        },
      },
    ],
  },
  {
    eventKey: 'consultas',
    label: 'Consultas',
    entidades: [
      {
        label: 'Cliente pré-aprovado por produto',
        permissoesMap: {
          [AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_CLIENTE_PRE_APROVADO_PRODUTO]: 'Visualização',
          [AcaoPermissaoPapelUsuarioEnum.CRIACAO_CLIENTE_PRE_APROVADO_PRODUTO]: 'Criação',
          [AcaoPermissaoPapelUsuarioEnum.REMOCAO_CLIENTE_PRE_APROVADO_PRODUTO]: 'Remoção',
        },
      },
      {
        label: 'Client credentials',
        permissoesMap: {
          [AcaoPermissaoPapelUsuarioEnum.CRIACAO_CLIENT_CREDENTIALS]: 'Criação',
          [AcaoPermissaoPapelUsuarioEnum.ALTERACAO_CLIENT_CREDENTIALS]: 'Alteração',
        },
      },
      {
        label: 'Embossing de cartões',
        permissoesMap: {
          [AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_EMBOSSING_CARTAO]: 'Visualização',
        },
      },
      {
        label: 'Grupo de clientes credenciais',
        permissoesMap: {
          [AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_GRUPO_CLIENT_CREDENTIALS]: 'Visualização',
          [AcaoPermissaoPapelUsuarioEnum.CRIACAO_GRUPO_CLIENT_CREDENTIALS]: 'Criação',
          [AcaoPermissaoPapelUsuarioEnum.ALTERACAO_GRUPO_CLIENT_CREDENTIALS]: 'Alteração',
        },
      },
      {
        label: 'Histórico de atividades de usuários',
        permissoesMap: {
          [AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_HISTORICO_ATIVIDADE_USUARIO]: 'Visualização',
        },
      },
      {
        label: 'Histórico de atividades externas',
        permissoesMap: {
          [AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_HISTORICO_ATIVIDADE_EXTERNA]: 'Visualização',
        },
      },
      {
        label: 'Lançamento contábil',
        permissoesMap: {
          [AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_LANCAMENTO_CONTABIL]: 'Visualização',
          [AcaoPermissaoPapelUsuarioEnum.CRIACAO_LANCAMENTO_CONTABIL]: 'Criação',
        },
      },
      {
        label: 'Pedidos de autorização',
        permissoesMap: {
          [AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_PEDIDO_AUTORIZACAO]: 'Visualização',
        },
      },
      {
        label: 'Protocolo de processamento de conta cartão',
        permissoesMap: {
          [AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_PROTOCOLO_PROCESSAMENTO_CONTA]: 'Visualização',
        },
      },
      {
        label: 'Protocolo de processamento de lote',
        permissoesMap: {
          [AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_PROTOCOLO_PROCESSAMENTO_LOTE]: 'Visualização',
          [AcaoPermissaoPapelUsuarioEnum.DOWNLOAD_ARQUIVO]: 'Download de arquivo',
        },
      },
      {
        label: 'Sequencial',
        permissoesMap: {
          [AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_SEQUENCIAL]: 'Visualização',
        },
      },
      {
        label: 'Solicitação de abertura de conta',
        permissoesMap: {
          [AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_SOLICITACAO_ABERTURA_CONTA]: 'Visualização',
        },
      },
      {
        label: 'Solicitação de clientes',
        permissoesMap: {
          [AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_SOLICITACAO_CLIENTE]: 'Visualização',
          [AcaoPermissaoPapelUsuarioEnum.CRIACAO_SOLICITACAO_CLIENTE]: 'Abertura de solicitação',
          [AcaoPermissaoPapelUsuarioEnum.ALTERACAO_STATUS_SOLICITACAO_CLIENTE]: 'Alteração de status',
          [AcaoPermissaoPapelUsuarioEnum.CRIACAO_INTERACAO_SOLICITACAO_CLIENTE]: 'Interação',
          [AcaoPermissaoPapelUsuarioEnum.CRIACAO_AJUSTE_FINANCEIRO_INTERACAO_SOLICITACAO_CLIENTE]:
            'Interação de ajuste financeiro',
          [AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_PAN_TOKEN_CARTAO]: 'Visualização do Pan Token',
        },
      },
      {
        label: 'Usuários',
        permissoesMap: {
          [AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_USUARIO]: 'Visualização',
          [AcaoPermissaoPapelUsuarioEnum.CRIACAO_USUARIO]: 'Criação',
          [AcaoPermissaoPapelUsuarioEnum.ALTERACAO_SENHA_USUARIO]: 'Alteração de senha',
          [AcaoPermissaoPapelUsuarioEnum.ALTERACAO_STATUS_USUARIO]: 'Alteração de status',
          [AcaoPermissaoPapelUsuarioEnum.ALTERACAO_PAPEL_USUARIO_USUARIO]: 'Alteração de papel usuário',
        },
      },
      {
        label: 'QMR',
        permissoesMap: {
          [AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_QMR]: 'Visualização',
        },
      },
    ],
  },
  {
    eventKey: 'operacao',
    label: 'Operação',
    entidades: [
      {
        label: 'Operação',
        permissoesMap: {
          [AcaoPermissaoPapelUsuarioEnum.EXECUCAO_ROTINA]: 'Execução de rotina',
          [AcaoPermissaoPapelUsuarioEnum.EXECUCAO_JORNADA_CONTAS_CARTAO]: 'Execução de jornada de contas-cartão',
          [AcaoPermissaoPapelUsuarioEnum.GERACAO_ARQUIVO]: 'Geração de arquivo',
          [AcaoPermissaoPapelUsuarioEnum.GERACAO_RELATORIO_OPERACAO]: 'Geração de relatórios de operação',
          [AcaoPermissaoPapelUsuarioEnum.IMPORTACAO_ARQUIVO]: 'Importação de arquivo',
          [AcaoPermissaoPapelUsuarioEnum.SIMULACAO_JUROS_ROTATIVO]: 'Simulação de juros rotativo',
        },
      },
    ],
  },
  {
    eventKey: 'parametrizacoes',
    label: 'Parametrizações',
    entidades: [
      {
        label: 'Calendários de corte',
        permissoesMap: {
          [AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_CALENDARIO_CORTE]: 'Visualização',
          [AcaoPermissaoPapelUsuarioEnum.CRIACAO_CALENDARIO_CORTE]: 'Criação',
        },
      },
      {
        label: 'Categorias benefício',
        permissoesMap: {
          [AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_CATEGORIA_BERNEFICIO]: 'Visualização',
        },
      },
      {
        label: 'Contratos termos de uso',
        permissoesMap: {
          [AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_CONTRATO_TERMOS_USO]: 'Visualização',
          [AcaoPermissaoPapelUsuarioEnum.CRIACAO_CONTRATO_TERMOS_USO]: 'Criação',
        },
      },
      {
        label: 'Emissor',
        permissoesMap: {
          [AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_EMISSOR]: 'Visualização',
          [AcaoPermissaoPapelUsuarioEnum.ALTERACAO_EMISSOR]: 'Alteração',
        },
      },
      {
        label: 'Faixas de MCC',
        permissoesMap: {
          [AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_FAIXA_MCC]: 'Visualização',
          [AcaoPermissaoPapelUsuarioEnum.CRIACAO_FAIXA_MCC]: 'Criação',
          [AcaoPermissaoPapelUsuarioEnum.ATUALIZACAO_FAIXA_MCC]: 'Alteração',
        },
      },
      {
        label: 'Feriados',
        permissoesMap: {
          [AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_FERIADO]: 'Visualização',
          [AcaoPermissaoPapelUsuarioEnum.CRIACAO_FERIADO]: 'Criação',
        },
      },
      {
        label: 'Itens de doação',
        permissoesMap: {
          [AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_ITEM_DOACAO]: 'Visualização',
          [AcaoPermissaoPapelUsuarioEnum.CRIACAO_ITEM_DOACAO]: 'Criação',
          [AcaoPermissaoPapelUsuarioEnum.ALTERACAO_ITEM_DOACAO]: 'Alteração',
          [AcaoPermissaoPapelUsuarioEnum.REMOCAO_ITEM_DOACAO]: 'Remoção',
        },
      },
      {
        label: 'Papéis de Usuários ',
        permissoesMap: {
          [AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_PAPEL_USUARIO]: 'Visualização',
          [AcaoPermissaoPapelUsuarioEnum.CRIACAO_PAPEL_USUARIO]: 'Criação',
          [AcaoPermissaoPapelUsuarioEnum.ALTERACAO_PAPEL_USUARIO]: 'Alteração',
        },
      },
      {
        label: 'Parâmetros de geração de boleto',
        permissoesMap: {
          [AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_PARAMETROS_GERACAO_BOLETO]: 'Visualização',
          [AcaoPermissaoPapelUsuarioEnum.CRIACAO_PARAMETROS_GERACAO_BOLETO]: 'Criação',
          [AcaoPermissaoPapelUsuarioEnum.ALTERACAO_PARAMETROS_GERACAO_BOLETO]: 'Alteração',
        },
      },
      {
        label: 'Parâmetros de geração de cartao',
        permissoesMap: {
          [AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_PARAMETROS_GERACAO_CARTAO]: 'Visualização',
          [AcaoPermissaoPapelUsuarioEnum.CRIACAO_PARAMETROS_GERACAO_CARTAO]: 'Criação',
          [AcaoPermissaoPapelUsuarioEnum.ALTERACAO_PARAMETROS_GERACAO_CARTAO]: 'Alteração',
        },
      },
      {
        label: 'Perguntas frequentes',
        permissoesMap: {
          [AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_PERGUNTA_FREQUENTE]: 'Visualização',
          [AcaoPermissaoPapelUsuarioEnum.CRIACAO_PERGUNTA_FREQUENTE]: 'Criação',
          [AcaoPermissaoPapelUsuarioEnum.REMOCAO_PERGUNTA_FREQUENTE]: 'Remoção',
        },
      },
      {
        label: 'Perguntas de segurança',
        permissoesMap: {
          [AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_PERGUNTA_SEGURANCA]: 'Visualização',
          [AcaoPermissaoPapelUsuarioEnum.CRIACAO_PERGUNTA_SEGURANCA]: 'Criação',
          [AcaoPermissaoPapelUsuarioEnum.ALTERACAO_PERGUNTA_SEGURANCA]: 'Alteração',
        },
      },
      {
        label: 'Prazos confirmação autorização por MCC',
        permissoesMap: {
          [AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_PRAZO_CONFIRMACAO_AUTORIZACAO_MCC]: 'Visualização',
          [AcaoPermissaoPapelUsuarioEnum.CRIACAO_PRAZO_CONFIRMACAO_AUTORIZACAO_MCC]: 'Criação',
          [AcaoPermissaoPapelUsuarioEnum.ALTERACAO_PRAZO_CONFIRMACAO_AUTORIZACAO_MCC]: 'Alteração',
        },
      },
      {
        label: 'Produtos',
        permissoesMap: {
          [AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_PRODUTO]: 'Visualização',
          [AcaoPermissaoPapelUsuarioEnum.ALTERACAO_PRODUTO]: 'Alteração',
        },
      },
      {
        label: 'Roteiros contabéis',
        permissoesMap: {
          [AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_ROTEIRO_CONTABIL]: 'Visualização',
          [AcaoPermissaoPapelUsuarioEnum.CRIACAO_ROTEIRO_CONTABIL]: 'Criação',
          [AcaoPermissaoPapelUsuarioEnum.ALTERACAO_ROTEIRO_CONTABIL]: 'Alteração',
        },
      },
      {
        label: 'Tarifas',
        permissoesMap: {
          [AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_TARIFA]: 'Visualização',
          [AcaoPermissaoPapelUsuarioEnum.CRIACAO_TARIFA]: 'Criação',
          [AcaoPermissaoPapelUsuarioEnum.ALTERACAO_TARIFA]: 'Alteração',
        },
      },
      {
        label: 'Taxas Câmbio ',
        permissoesMap: {
          [AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_TAXA_CAMBIO]: 'Visualização',
          [AcaoPermissaoPapelUsuarioEnum.CRIACAO_TAXA_CAMBIO]: 'Criação',
        },
      },
      {
        label: 'Taxas médias Selic',
        permissoesMap: {
          [AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_TAXA_MEDIA_SELIC]: 'Visualização',
        },
      },
      {
        label: 'Tipos de bloqueio de cartão',
        permissoesMap: {
          [AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_TIPO_BLOQUEIO_CARTAO]: 'Visualização',
          [AcaoPermissaoPapelUsuarioEnum.CRIACAO_TIPO_BLOQUEIO_CARTAO]: 'Criação',
          [AcaoPermissaoPapelUsuarioEnum.ALTERACAO_TIPO_BLOQUEIO_CARTAO]: 'Alteração',
        },
      },
      {
        label: 'Tipos de bloqueio de conta cartão',
        permissoesMap: {
          [AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_TIPO_BLOQUEIO_CONTA_CARTAO]: 'Visualização',
          [AcaoPermissaoPapelUsuarioEnum.CRIACAO_TIPO_BLOQUEIO_CONTA_CARTAO]: 'Criação',
          [AcaoPermissaoPapelUsuarioEnum.ALTERACAO_TIPO_BLOQUEIO_CONTA_CARTAO]: 'Alteração',
        },
      },
      {
        label: 'Tipo de solicitação de clientes',
        permissoesMap: {
          [AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_TIPO_SOLICITACAO_CLIENTE]: 'Visualização',
          [AcaoPermissaoPapelUsuarioEnum.CRIACAO_TIPO_SOLICITACAO_CLIENTE]: 'Criação',
        },
      },
      {
        label: 'Tipo de transação',
        permissoesMap: {
          [AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_TIPO_TRANSACAO]: 'Visualização',
          [AcaoPermissaoPapelUsuarioEnum.CRIACAO_TIPO_TRANSACAO]: 'Criação',
          [AcaoPermissaoPapelUsuarioEnum.ALTERACAO_TIPO_TRANSACAO]: 'Alteração',
        },
      },
    ],
  },
  {
    eventKey: 'relatoriosOperacao',
    label: 'Relatórios',
    entidades: [
      {
        label: 'Autorizações',
        permissoesMap: {
          [AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_RELATORIO_OPERACAO_AUTORIZACOES]: 'Visualização',
        },
      },
    ],
  },
];

type PermissoesPapelUsuarioTabsProps = {
  permissoes: { [key in AcaoPermissaoPapelUsuarioEnum]: boolean };
  readOnly?: boolean;
  formControl?: Control<PapelUsuarioFormFields>;
};

export type PapelUsuarioFormFields = {
  descricao: string;
  permissoes: { [key in AcaoPermissaoPapelUsuarioEnum]: boolean };
};

const PermissoesPapelUsuarioTabs: React.FC<PermissoesPapelUsuarioTabsProps> = ({
  permissoes,
  readOnly = false,
  formControl,
}) => {
  return (
    <BSTabs defaultActiveKey="contasCartao" fill unmountOnExit>
      {permissoesEntidades.map((permissaoEntidade) => (
        <BSTab key={permissaoEntidade.eventKey} eventKey={permissaoEntidade.eventKey} title={permissaoEntidade.label}>
          <PermissoesPapelUsuarioTab
            entidades={permissaoEntidade.entidades}
            permissoes={permissoes}
            readOnly={readOnly}
            formControl={formControl}
          />
        </BSTab>
      ))}
    </BSTabs>
  );
};

type PermissoesPapelUsuarioTabProps = PermissoesPapelUsuarioTabsProps & {
  entidades: PermissaoEntidadePapelUsuarioProps[];
};

const PermissoesPapelUsuarioTab: React.FC<PermissoesPapelUsuarioTabProps> = ({
  entidades,
  readOnly,
  formControl,
  permissoes,
}) => {
  return (
    <>
      {entidades.map((entidade, index) => (
        <React.Fragment key={entidade.label}>
          <div className="my-3">
            <div className="mb-4">
              <strong>{entidade.label}</strong>
            </div>

            <BSListGroup variant="flush">
              <div className="ps-3">
                {Object.entries(entidade.permissoesMap).map(([key, label]) => (
                  <BSListGroup.Item className="border-0" key={key}>
                    {readOnly ? (
                      <BSForm.Check
                        className="form-checkbox"
                        label={label}
                        type="checkbox"
                        checked={permissoes[key as AcaoPermissaoPapelUsuarioEnum] || false}
                        readOnly
                      />
                    ) : (
                      <FormCheck
                        control={formControl}
                        name={`permissoes.${key as AcaoPermissaoPapelUsuarioEnum}`}
                        label={label}
                        type="checkbox"
                      />
                    )}
                  </BSListGroup.Item>
                ))}
              </div>
            </BSListGroup>
          </div>

          {Boolean(index !== entidades.length - 1) && (
            <div className="my-4">
              <hr />
            </div>
          )}
        </React.Fragment>
      ))}
    </>
  );
};

export default PermissoesPapelUsuarioTabs;
