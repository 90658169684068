import { reducerFactory, actionFactory } from '../../store';

const context = 'jornadaContasCartao';

const { createOne } = actionFactory(context, {
  buildUrlCreateOne: () => '/api/operacao/jornada-contas-cartao',
});

export const executarJornadaContasCartao = createOne;

export const jornadaContasCartao = reducerFactory({ context });
