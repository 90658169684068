import { useCallback } from 'react';
import BSBadge from 'react-bootstrap/Badge';
import BSCard from 'react-bootstrap/Card';
import { Link, useNavigate } from 'react-router-dom';

import { AcaoPermissaoPapelUsuarioEnum } from '@tamborineapps/lib-enums';
import RbacPage from '../../components/role-based-access-control/role-based-access-control-page';
import { SmartTable } from '../../components/smart-table/smart-table';
import SmartTableFilters from '../../components/smart-table/smart-table-filters/smart-table-filters';
import {
  SmartTableFilterControlTypesEnum,
  SmartTableFilterProps,
} from '../../components/smart-table/smart-table-filters/table-filter.types';
import { SmartTableColumnProps } from '../../components/smart-table/smart-table-header';
import Title from '../../components/title';
import {
  ApiMultiElementResponse,
  ClassTypesEnum,
  FormatValueEnum,
  mapearERemoverElementosNulosERepetidos,
  tipoProcessamentoJornadaContaCartaoLabelMap,
} from '../../helpers';
import { useQuerystring } from '../../hooks/router/use-querystring';
import { useToasts } from '../../hooks/toast/use-toasts';
import { useAppDispatch, useAppSelector } from '../../store/hooks-redux';
import { loadContasCartao, selectObjectTodasContasCartao } from '../contas-cartao/contas-cartao.redux';
import {
  loadProtocolosProcessamentoConta,
  selectLoadingStateByFiltersProtocoloProcessamentoConta,
  selectProtocolosProcessamentoContaByFilters,
  selectTotalProtocolosProcessamentoContaByFilters,
} from './protocolo-processamento-conta.redux';

const smartFilters: SmartTableFilterProps[] = [
  {
    attribute: 'data',
    label: 'Data referência',
    controlType: SmartTableFilterControlTypesEnum.DATE_RANGE,
  },
  {
    attribute: 'numero',
    label: 'Número da conta',
    controlType: SmartTableFilterControlTypesEnum.TEXT_INPUT,
  },
  {
    attribute: 'tipoProcessamento',
    label: 'Data de referência',
    controlType: SmartTableFilterControlTypesEnum.SELECT,
    map: tipoProcessamentoJornadaContaCartaoLabelMap,
  },
  {
    label: 'Sucesso',
    attribute: 'sucesso',
    controlType: SmartTableFilterControlTypesEnum.BOOL_RADIO,
  },
];

const smartColumns = ({ contasCartao }: { contasCartao: any }): SmartTableColumnProps[] => [
  {
    label: 'Data referência',
    attribute: 'data',
    className: 'text-center',
    sortable: true,
    format: FormatValueEnum.DATA,
  },
  {
    label: 'Tipo de processamento',
    attribute: 'tipoProcessamento',
    className: 'text-center',
    sortable: true,
    format: FormatValueEnum.ENUM,
    map: tipoProcessamentoJornadaContaCartaoLabelMap,
  },
  {
    label: 'Conta Cartão',
    attribute: 'contaCartaoId',
    className: 'text-center',
    format: (contaCartaoId: any) => {
      return (
        <Link onClick={(e) => e.stopPropagation()} to={`/contas-cartao/${contaCartaoId}`}>
          {contasCartao[contaCartaoId]?.numero}
        </Link>
      );
    },
  },
  {
    label: 'Status',
    attribute: 'processadoComSucesso',
    sortable: true,
    className: 'text-center',
    format: (processadoComSucesso: boolean) => {
      return (
        <h5 className="m-0">
          <BSBadge pill bg={processadoComSucesso ? ClassTypesEnum.SUCCESS : ClassTypesEnum.DANGER}>
            {processadoComSucesso ? 'Sucesso' : 'Falha'}
          </BSBadge>
        </h5>
      );
    },
  },
  {
    label: 'Tentativas',
    attribute: 'numeroTentativas',
    sortable: true,
    className: 'text-center',
  },
];

const ProtocolosProcessamentoContaPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const useSelector = useAppSelector;

  const { query, maxItemsQuery } = useQuerystring();
  const navigate = useNavigate();
  const { showToast } = useToasts();

  const total = useSelector((state) => selectTotalProtocolosProcessamentoContaByFilters(state, query));
  const protocolosProcessamentoConta = useSelector((state) =>
    selectProtocolosProcessamentoContaByFilters(state, query)
  );
  const loadingState = useSelector((state) => selectLoadingStateByFiltersProtocoloProcessamentoConta(state, query));
  const contasCartao = useSelector((state) => selectObjectTodasContasCartao(state));

  const loadEntidadesComplementares = useCallback(
    (protocolosProcessamentoConta: any[]) => {
      const contasCartaoIds = mapearERemoverElementosNulosERepetidos(protocolosProcessamentoConta, 'contaCartaoId');

      dispatch(loadContasCartao({ query: { _id: { in: contasCartaoIds }, ...maxItemsQuery } })).catch(
        (error: Error) => error
      );
    },
    [dispatch, maxItemsQuery]
  );

  const loadItems = useCallback(() => {
    dispatch(loadProtocolosProcessamentoConta({ query }))
      .then(({ payload: { data } }: ApiMultiElementResponse) => {
        if (Object.entries(query).length > 0 && data.length === 0) {
          showToast({
            message: 'Nenhum protocolo de processamento de conta-cartão encontrado',
            type: ClassTypesEnum.WARNING,
          });
        }

        return loadEntidadesComplementares(data);
      })
      .catch((error: Error) => error);
  }, [dispatch, loadEntidadesComplementares, query, showToast]);

  return (
    <RbacPage acoesPermissao={AcaoPermissaoPapelUsuarioEnum.VISUALIZACAO_PROTOCOLO_PROCESSAMENTO_CONTA}>
      <div className="d-flex flex-column h-100">
        <Title>Processamentos conta cartão</Title>

        <div className="row">
          <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-12 mb-4">
            <SmartTableFilters filters={smartFilters} />
          </div>

          <div className="col-xxl-10 col-xl-9 col-lg-8 col-md-12 mb-4">
            <BSCard>
              <BSCard.Body>
                <SmartTable
                  emptyMessage="Pesquise por protocolos de processamento"
                  errorMessage="Erro na listagem de protocolos de processamento de conta cartão"
                  usePagination={true}
                  loadItems={loadItems}
                  columns={smartColumns({ contasCartao })}
                  items={protocolosProcessamentoConta}
                  loadingState={loadingState}
                  onRowClick={(doc) => navigate(`/protocolos-processamento-conta/${doc._id}`)}
                  size={total}
                />
              </BSCard.Body>
            </BSCard>
          </div>
        </div>
      </div>
    </RbacPage>
  );
};

export default ProtocolosProcessamentoContaPage;
